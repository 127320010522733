<template>
    <div class="select-wrapper" v-if="data.length > 0 && !readOnly">
        <div :class="'select-value ' + ((active)?'active':'')" @click="toggleOptions" ref="appselect" tabindex="0" @keydown="keyValue">
            <div v-if="selectedValue !== ''">{{ $Lang.getTitle(selectedValue) }}</div>
            <div v-else class="placeholder">{{ (placeholder !== '') ? $Lang.getTitle(placeholder) : $Lang.get('none') }}</div>
        </div>
        <ul :class="'select-options '" v-show="active" @click="preventDefault" ref="select-options">
            <li 
                v-if="canEmpty" 
                @click="selectOption($event.target)" 
                :value="''" 
                index="0"
                tabindex="0"
                @keydown="keyDownOption"
                @keyup="keyUpOption"
            >{{ (empty_value !== '') ? $Lang.getTitle(empty_value) : $Lang.get('none') }}</li>
            <li 
                v-for="(d,index) in data" 
                :data-value="d.id" 
                :data-display="getDisplayValue(d)" 
                :key="index"
                :index="index + 1"
                tabindex="0"
                @click="selectOption($event.target)" 
                @keydown="keyDownOption"
                @keyup="keyUpOption"
            >{{ getDisplayValue(d) }}</li>
        </ul>
        <select v-model="selected">
            <option v-if="canEmpty" v-bind:value="''" v-bind:selected="selected==''"></option>
            <option v-for="d in data" :key="d.id" v-bind:value="d.id" v-bind:selected="selected==d.id">{{ getDisplayValue(d) }}</option>
        </select>
    </div>
    <template v-else>
        <template v-for="d in data" :key="d.id">
            <div :class="(index !== null && index !== undefined && index > 0) ? 'next' : ''" v-if="d.id == value">{{ getDisplayValue(d) }}</div>
        </template>
    </template>
</template>

<script>
export default {
    props: {
        value: { type:String, default:'' },
        data: { type:Object, default:null },
        type: { type:String, default:'text' },
        display_field: null,
        field: String,
        index: Number,
        canEmpty: { type:Boolean, default:true },
        parent_value: String,
        empty_value: { type:String, default:'' },
        readOnly: { type:Boolean, default:false },
        placeholder: { type:String, default:'' }
    },
    data () {
        return {
            active: false,
            selected: '',
            selectedValue: ''
        }
    },
    created() {
        if (this.data !== null) {
            this.selected = this.value
            this.$emit('updateDefault', this.value)
            for (let i in this.data) {
                if (this.data[i].id == this.selected) {
                    if (typeof this.display_field === 'object') {
                        for (let j in this.display_field) {
                            if (this.seletedValue !== '') this.selectedValue += ' '
                            if (this.display_field[j] !== '-') {
                                this.selectedValue += this.$Lang.getTitle(this.data[i][this.display_field[j]])
                            } else {
                                this.selectedValue += ' - ';
                            }
                        }
                    } else {
                        this.selectedValue = this.$Lang.getTitle(this.data[i][this.display_field])
                    }
                }
            }
            if (this.selectedValue === '') this.selectedValue = this.empty_value
            document.addEventListener('click', this.click)
            document.addEventListener('keydown', function(e) {
                if (e.key == 'ArrowDown' || e.key == 'ArrowUp' ) {
                    e.preventDefault();
                }
            })

            if (!this.canEmpty) {
                if (this.selected === '' && this.selectedValue === '') {
                    // Auto Select First
                        for (let i in this.data) {
                            this.selected = this.data[i].id
                            if (typeof this.display_field === 'object') {
                                for (let j in this.display_field) {
                                    if (this.seletedValue !== '') this.selectedValue += ' '
                                    if (this.display_field[j] !== '-') {
                                        this.selectedValue += this.$Lang.getTitle(this.data[i][this.display_field[j]])
                                    } else {
                                        this.selectedValue += ' - ';
                                    }
                                }
                            } else {
                                this.selectedValue = this.$Lang.getTitle(this.data[i][this.display_field])
                            }
                            break
                        }
                }
            }
        }
    },
    emits:[ 'update', 'updateDefault' ],
    watch: {
        parent_value () {
            this.selected = ''
            this.selectedValue = ''
        }
    },
    methods: {
        getDisplayValue(d) {
            let str = ''
            if (typeof this.display_field === 'object') {
                for(let i in this.display_field)
                {
                    if (str !== '') str += ' '
                    if (this.display_field[i] !== '-') {
                        str += this.$Lang.getTitle(d[this.display_field[i]])
                    } else {
                        str += ' - ';
                    }
                }
            } else {
                str = this.$Lang.getTitle(d[this.display_field])
            }
            return str
        },
        toggleOptions () {
            this.active = (this.active) ? false : true
            this.$nextTick(() => {
                try {
                    let o = this.$refs['select-options']
                    o.style['max-height'] = 'inherit'
                    let h = o.clientHeight
                    if (o.offsetParent) {
                        var pt = o.offsetParent.offsetTop
                        var ph = o.offsetParent.clientHeight
                        o.style['top'] = ph + 'px'
                        let scrollTop = document.documentElement.scrollTop || window.pageYOfset ||document.body.scrollTop;
                        let windowHeight = window.innerHeight
                        
                        if (pt - scrollTop <= windowHeight / 2) {
                            o.style['max-height'] = (windowHeight - (pt - scrollTop) - ph) + 'px'
                        } else {
                            o.style['max-height'] = windowHeight - (windowHeight - (pt - scrollTop)) + 'px'
                            h = o.clientHeight
                            o.style['top'] = (h * -1) + 'px'
                        }
                    }
                } catch (e) {
                    console.log(e)
                }
            })
        },
        selectOption (e) {
            this.selectedValue = e.getAttribute('data-display') || this.empty_value
            this.selected = e.getAttribute('data-value')
            this.toggleOptions()
            if (this.index !== undefined) {
                this.$emit('update', (this.selected !== '' && this.selected !== null) ? this.selected.toString() :'', this.index)
            } else {
                this.$emit('update', (this.selected !== '' && this.selected !== null) ? this.selected.toString() :'')
            }
        },
        click (e) {
            let el = this.$refs.appselect
            let target = e.target
            if (el !== target) {
                this.active = false
            }
        },
        preventDefault(e) {
            e.stopPropagation()
        },
        keyValue(e) {
            switch(e.key) {
                case 'ArrowDown' :
                    this.active = true
                    var li = e.target.parentNode.childNodes[1].getElementsByTagName('li')
                    li[0].focus()
                    break
                case 'Escape' :
                    this.active = false
                    break
            }
        },
        keyDownOption(e) {
            var li = e.target.parentNode.getElementsByTagName('li')
            var index = parseInt(e.target.getAttribute('index'))
            switch (e.key) {
                case 'ArrowDown' :
                    if (index + 1 < li.length) {
                        li[index + 1].focus()
                    }
                    break
                case 'ArrowUp' :
                    if (index - 1 >= 0) {
                        li[index - 1].focus()
                    }
                    break
            }
        },
        keyUpOption(e) {
            switch (e.key) {
                case 'Escape' :
                    this.active = false
                    break
                case 'Enter' :
                    e.target.click()
                    break
            }
        }
    }
}
</script>

<style scoped lang="scss">
select { display:none; }
.select-wrapper
{
    user-select:none;
    position:relative;
    .select-value {
        position:relative;
        border:1px solid var(--layout-border-color); 
        padding:0px 20px;
        padding-right:50px;
        box-sizing:border-box;
        font-size:16px;
        line-height:48px;
        height:48px;
        width:100%;
        border-radius:8px;
        background:#ffffff;
        cursor:pointer;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
        color:#333;
        &:after {
            mask:url('~@/assets/icon-arrow.png') no-repeat center center;
            background:var(--form-group-title-background-color);
            width:48px;
            height:48px;
            position:absolute;
            top:0px;
            right:0px;
            cursor:pointer;
            content:'';
        }
        div { pointer-events:none; }
        .placeholder {
            color:#c1c1c1;
        }
    }
    .select-options {
        margin:0px; padding:0px; list-style:none;
        border:1px solid var(--layout-border-color);
        position:absolute;
        top:35px;
        left:0px;
        width:100%;
        box-sizing:border-box;
        box-shadow:0px 10px 10px rgba(0,0,0,0.3);
        overflow:auto;
        z-index:10000;
        color:#333;
        li {
            padding:0px 10px; line-height:34px; font-size:16px;
            height:34px;
            background:#ffffff;
            display:block;
            cursor:pointer;
            text-overflow:ellipsis;
            &:hover {
                background:#dedede;
            }
            &:focus {
                background:#dedede;
            }
        }
    }
}
@media only screen and (max-width:768px) {
    .select-wrapper
    {
        .select-value {
            &.active {
                position:fixed;
                top:0px; left:0px;
                width:100%;
                z-index:20000;
                border-radius:0px;
                background:var(--form-group-title-background-color);
                color:var(--form-group-title-text-color);
                cursor:default;
            }
            &:after {
                background:#ffffff;
            }
            .placeholder {
                color:#eee;
            }
        }
        .select-options {
            position:fixed;
            top:48px;
            left:0px;
            width:100vw;
            height:calc(100vh - 48px);
            z-index:100000;
            background:#ffffff;
        }
    }
}
</style>
