<template>
    <ul class="menu" v-if="!isQuestionnaire">
        <li><router-link to="/profile/" :class="[(section=='profile') ? 'active' :'']"><span class="icon profile"></span>修改個人資料</router-link></li>
        <li><router-link to="/edit-questionnaire/" :class="[(section=='edit-questionnaire') ? 'active' : '']"><span class="icon profile"></span>修改入學問卷</router-link></li>
        <li><router-link to="/change-password/" :class="[(section=='change-password') ? 'active' :'']"><span class="icon change-password"></span>更改密碼</router-link></li>
        <li><router-link to="/logout/" :class="[(section=='logout') ? 'active' :'']"><span class="icon logout"></span>登&nbsp;&nbsp;出</router-link></li>
    </ul>
</template>

<script>
export default {
    props: {
        section: { type: String, default:'' }
    },
    data() {
        return {
            isQuestionnaire: false
        }
    },
    mounted() {
        this.isQuestionnaire = this.$root.getQuestionnaire()
    }
}
</script>

<style scoped lang="scss">
.menu {
    width:100%;
    
    display:flex;
    justify-content:center;
    column-gap:20px;
    list-style:none;
    margin:0px 0px 70px 0px;
    padding:0px;

    li {
        a {
            background:rgba(0,0,0,0);
            line-height:30px;
            padding:10px 30px;
            border-radius:8px;
            box-sizing:border-box;
            text-shadow:none;
            text-decoration:none;
            vertical-align:middle;

            &:hover {
                background:#19799e;
                box-shadow:1px 1px 9px inset rgba(0,0,0,0.2);
            }

            &.active {
                background:#19799e;
                box-shadow:1px 1px 9px inset rgba(0,0,0,0.2);
            }

            .icon {
                background:no-repeat center center;
                background-size:contain;
                width:14px;
                height:14px;
                display:inline-block;
                margin-right:5px;
                vertical-align:top;
                margin-top:7px;

                &.profile {
                    background-image:url('~@/assets/icon-profile.png');
                }
                &.change-password {
                    background-image:url('~@/assets/icon-change-password.png');
                }
                &.logout {
                    background-image:url('~@/assets/icon-logout.png');
                }
            }
        }
    }
}

@media only screen and (max-width:700px) {
    .menu {
        margin-bottom:40px;
        padding:0px 5px;
        box-sizing:border-box;
        column-gap:3px;
        li {
            a {
                white-space:nowrap;
                padding:10px 6px;
                font-size:12px;
            }
        }
    }
}
</style>