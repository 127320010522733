<template>
    <div class="page" v-if="ready">
        <ProfileMenu section="profile" />
        <div>
            <h1>修改個人資料</h1>
        </div>

        <div class="form-wrapper" v-if="!isSaved">
            <div class="legend no-input">
                <ul class="group" v-if="data.username">
                    <li>用戶名稱</li>
                    <li>{{ data.username }}</li>
                </ul>
                <ul class="group" v-if="data.customer_number">
                    <li>客戶編號</li>
                    <li>{{ data.customer_number }}</li>
                </ul>
                <ul class="group" v-if="data.branch_name">
                    <li>所屬分校</li>
                    <li>{{ data.branch_name }}</li>
                </ul>
                <ul class="group" v-if="data.enrollment_date">
                    <li>入學日期</li>
                    <li>{{ getDateFormat(data.enrollment_date) }}</li>
                </ul>
            </div>

            <div class="legend">
                <h3>學生資料</h3>
                <ul class="group">
                    <li><label>中文全名 (*)</label></li>
                    <li>
                        <input type="text" v-model="form.tc_name" required ref="tc_name" />
                        <div v-if="error === 'tc_name'" class="error">請填寫中文全名</div>
                    </li>
                </ul>
                <ul class="group">
                    <li><label>英文全名 (*)</label></li>
                    <li>
                        <input type="text" v-model="form.en_name" required ref="en_name" />
                        <div v-if="error === 'en_name'" class="error">請填寫英文全名</div>
                    </li>
                </ul>
                <ul class="group">
                    <li><label>出生日期 (*)</label></li>
                    <li>
                        <div class="date-picker">
                            <datePicker v-model="form.dob" :locale="locale" :upperLimit="upperLimit" :lowerLimit="lowerLimit" startingView="year" :weekStartsOn="0" />
                            <div v-if="error === 'dob'" class="error">請選擇出生日期</div>
                        </div>
                        <div>年齡 : {{ calculateAge() }}</div>
                    </li>
                </ul>
                <ul class="group">
                    <li><label>性別 (*)</label></li>
                    <li>
                        <div class="group-radio">
                            <div><input type="radio" v-model="form.gender" value="M" :checked="(form.gender == 'M')?true:false" /><label>男</label></div>
                            <div><input type="radio" v-model="form.gender" value="F" :checked="(form.gender == 'F')?true:false" /><label>女</label></div>
                        </div>
                        <div v-if="error === 'gender'" class="error">請選擇學生性別</div>
                    </li>
                </ul>
                <ul class="group">
                    <li><label>學校 (*)</label></li>
                    <li>
                        <input type="text" v-model="form.school_name" required ref="school_name" />
                        <div v-if="error === 'school_name'" class="error">請填寫學校全名</div>
                    </li>
                </ul>
                <ul class="group">
                    <li><label>級別</label></li>
                    <li>
                        <app-select :value="form.school_level" :data="school_level" display_field="name" @update="updateSchoolLevel" />
                    </li>
                </ul>
            </div>
            <div class="legend">
                <h3>通訊地址</h3>
                <ul class="group">
                    <li><label>住址地區</label></li>
                    <li>
                        <app-select :value="form.district_id" :data="district" display_field="tc_name" @update="updateDistrict" />
                    </li>
                </ul>
                <ul class="group">
                    <li><label>中文住址</label></li>
                    <li>
                        <input type="text" v-model="form.tc_address" />
                    </li>
                </ul>
                <ul class="group">
                    <li><label>英文住址</label></li>
                    <li>
                        <input type="text" v-model="form.en_address" />
                    </li>
                </ul>
            </div>
            <div class="legend">
                <h3>聯絡人 (一)</h3>
                <ul class="group">
                    <li><label>聯絡人名稱 (*)</label></li>
                    <li>
                        <input type="text" v-model="form.contact_1_name" required ref="contact_1_name" />
                        <div v-if="error === 'contact_1_name'" class="error">請填寫聯絡人名稱</div>
                    </li>
                </ul>
                <ul class="group">
                    <li><label>聯絡人電話號碼 (*)</label></li>
                    <li>
                        <input type="text" v-model="form.contact_1_phone" required ref="contact_1_phone" />
                        <div v-if="error === 'contact_1_phone'" class="error">請填寫聯絡人電話號碼</div>
                    </li>
                </ul>
                <ul class="group">
                    <li><label>聯絡人電郵地址 (*)</label></li>
                    <li>
                        <input type="text" v-model="form.contact_1_email" required ref="contact_1_email" />
                        <div v-if="error === 'contact_1_email'" class="error">請填寫聯絡人電郵地址</div>
                        <div v-if="error === 'contact_1_email_validation'" class="error">請輸入有效的電郵地址</div>
                    </li>
                </ul>
            </div> 
            <div class="legend">
                <h3>聯絡人 (二)</h3>
                <ul class="group">
                    <li><label>聯絡人名稱</label></li>
                    <li>
                        <input type="text" v-model="form.contact_2_name" />
                    </li>
                </ul>
                <ul class="group">
                    <li><label>聯絡人電話號碼</label></li>
                    <li>
                        <input type="text" v-model="form.contact_2_phone" />
                    </li>
                </ul>
                <ul class="group">
                    <li><label>聯絡人電郵地址</label></li>
                    <li>
                        <input type="text" v-model="form.contact_2_email" ref="contact_2_email" />
                        <div v-if="error === 'contact_2_email_validation'" class="error">請輸入有效的電郵地址</div>
                    </li>
                </ul>

            </div> 
            <div class="control" v-if="!submitting && !submit_error">
                <input type="submit" value="儲存" @click="submit" />
            </div>
            <div class="loader" v-if="submitting && !submit_error">
                <span class="icon-loading"></span><span>資料儲存中...</span>
            </div>
        </div>

        <div class="successfully-page" v-if="isSaved">
            <div class="message">
                您的個人資料已成功儲存
            </div>
            <div class="control">
                <input type="button" value="返回" @click="back" />
            </div>
        </div>
    </div>
</template>

<script src="./Profile.js"></script>

<style scoped lang="scss">
@import 'Page.scss';
</style>
 