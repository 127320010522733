import ProfileMenu from './ProfileMenu'
import zh from 'date-fns/locale/zh-TW'
import datePicker from 'vue3-datepicker'
import select from '@/components/elements/select'
import Fn from './Fn'
export default {
    mixins:[ Fn ],
    components: { 
        datePicker, 
        'app-select': select,
        ProfileMenu
    },
    data() {
        return {
            ready: false,
            isSaved: false,
            locale: zh,
            upperLimit: null,
            lowerLimit: null,
            error: '',
            data: null,
            submit_error: false,
            submitting: false,
            form: {},
            formField: [ 
                'tc_name', 'en_name', 'dob', 'email', 'gender', 'en_address', 'tc_address', 'district_id', 'school_name', 'school_level',
                'contact_1_name', 'contact_1_phone', 'contact_1_email',
                'contact_2_name', 'contact_2_phone', 'contact_2_email'
            ],
            requiredField: [
                'tc_name', 'en_name', 'dob', 'gender', 'school_name',
                'contact_1_name', 'contact_1_phone', 'contact_1_email'
            ],
            emailField: [
                'contact_1_email', 'contact_2_email'
            ],
            district: [],
            school_level: []
        }
    },
    mounted() {
        this.$root.updateNotification()
        this.upperLimit = this.getLimitDate('today')
        this.lowerLimit = this.getLimitDate({ year:-20 })

        const params = new FormData()
        params.append('token', this.Auth.getToken())

        this.$axios
            .post(process.env.VUE_APP_API + 'profile' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
            .then(response => {
                let r = response.data
                if (r.success === true) {
                    this.data = r.data
                    this.district = r.district
                    this.school_level = r.school_level

                    for (let i in this.formField) {
                        let field = this.formField[i]
                        switch (field) {
                            case 'dob' : {
                                this.form[field] = (this.data[field] !== null && this.data[field] !== '') ? new Date(this.data[field]) : null
                                break
                            }
                            default : {
                                this.form[field] = this.data[field]
                                break
                            }
                        }
                    }
                    this.ready = true
                }
            })
    },
    methods: {
        calculateAge() {
            if (this.form.dob) {
                let dob = new Date(this.form.dob)
                if (dob) {
                    let diff = Date.now() - dob.getTime()
                    let age = new Date(diff)
                    let month = (age.getUTCMonth() > 0 ) ? ' ' + age.getUTCMonth() + '個月' : ''
                    return Math.abs(age.getUTCFullYear() - 1970) + '歲' + month
                }
            }
        },
        updateSchoolLevel(value) {
            this.form.school_level = value
        },
        updateDistrict(value) {
            this.form.district_id = value
        },
        back() {
            this.isSaved = false
        },
        submit() {
            this.submitting = true
            this.error = false
        
            for (let i in this.requiredField) {
                if (this.form[this.requiredField[i]] == '') {
                    this.error = this.requiredField[i]
                    this.$refs[this.requiredField[i]].focus()
                    break
                }
            }

            for (let i in this.emailField) {
                if (this.form[this.emailField[i]] !== '') {
                    if (!this.validEmailAddress(this.form[this.emailField[i]])) {
                        this.error = this.emailField[i]+'_validation'
                        this.$refs[this.emailField[i]].focus()
                        break
                    }
                }
            }


            if (this.error == false) {
                const params = new FormData()
                for (let i in this.form) {
                    switch (i) {
                        case 'dob' : {
                            let date = new Date(this.form[i])
                            params.append(i, date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate())
                            break
                        }
                        default : {
                            params.append(i, this.form[i])
                            break
                        }
                    }
                }
                params.append('token', this.Auth.getToken())

                this.$axios
                    .post(process.env.VUE_APP_API + 'profile-db' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                    .then(response => {
                        let r = response.data
                        if (r.success === true) {
                            this.isSaved = true
                        }

                        this.submitting = false
                    })
            } else {
                this.submitting = false
            }
        },
        unsubscribe() { }
    }
}